/* eslint-disable @typescript-eslint/no-explicit-any */
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase/firebase";
// import { useUserInfo } from "../useUserInfo";

export enum EventName {
  LANDINGPAGE_CLICK_CTA = "landingpage_click_cta",
}

export const useFirebaseTracking = () => {
  // const { user } = useUserInfo();
  const onEventTracking = (event: EventName, params = {}) => {
    if (!process.env.REACT_APP_ENABLE_TRACKING) return;
    console.log('passsss');
    
    const data: any = {
      ...params,
    };
    console.log('[PARAMS]: ', data);
    
    logEvent(analytics, event, data);
  };

  return {
    onEventTracking,
  };
};
