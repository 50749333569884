import React, { useRef } from "react";
import { Background, ButtonCustom, CTA, Desc, Header, HeaderTitle, ImgClock, LottieWrapper, MainContent, PeopleNumber, Wrapper } from "./styles";
import icHeart from '../../assets/icons/ic-heart.svg';
import icOpenLink from '../../assets/icons/ic-open-link.svg';
import bg from '../../assets/images/bg.png';
import Lottie from 'react-lottie';
import imgClock from '../../assets/images/clock-img.png';
import useScreenSize from "../../hooks/firebase/useScreenSize";
import { EventName, useFirebaseTracking } from "../../hooks/firebase/useFirebaseTracking";

const Home = () => {
  const { isMobile } = useScreenSize();
  const animationRef = useRef(null);
  const { onEventTracking } = useFirebaseTracking();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    path: 'https://static.apero.vn/Pulse-Track/ob1.json',
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const goToStore = () => {
    console.log('zooo');
    
    onEventTracking(EventName.LANDINGPAGE_CLICK_CTA)
    window.open('https://apps.apple.com/vn/app/pulsevista-heartbeat-monitor/id6618118303?l=vi', '_blank')
  }
  return(
    <Wrapper>
      <Header>
        <img src={icHeart} width={62} height={62} alt="ic-heart"/>
        <HeaderTitle>PulseVista</HeaderTitle>
      </Header>
      <MainContent>
        <LottieWrapper>
          <Lottie
            options={defaultOptions}
            width={isMobile ? 240 : 369}
            height={isMobile ? 320 : 478}
            ref={animationRef}
            style={{ background: 'transparent' }}
          />
          <ImgClock src={imgClock} width={150} height={194} alt="img-clock"/>
        </LottieWrapper>
        <Background src={bg} alt="bg" />
        <Desc>Join <PeopleNumber>1,098,006</PeopleNumber> people and take control over your heart health</Desc>
      </MainContent>
      <CTA>
        <ButtonCustom onClick={goToStore}>
          Try PulseVista
          <img src={icOpenLink} width={24} height={24} alt="ic-open-link"/>
        </ButtonCustom>
      </CTA>
    </Wrapper>
  )
}

export default React.memo(Home);