import React from 'react';
import './App.css';
import {RouterProvider} from "react-router-dom";
import {router} from "./router";
import {RecoilRoot} from "recoil";
import eruda from 'eruda'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
const queryClient = new QueryClient()

function App() {
  // eruda.init()
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
