import styled from "styled-components";
import { breakpoints } from "../../config/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #F2F6FF;
  padding: 48px;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  background: linear-gradient(to bottom, #DD445A, #DD445A33);
  gap: 16px;
  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 24px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  z-index: 1000;
`
export const HeaderTitle = styled.div`
  font-family: Outfit;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
`

export const MainContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 24px;
  // width: 100%;
`

export const CTA = styled.div`
  margin-top: 16px;
  z-index: 99999999999;
`
export const Background = styled.img`
  object-fit: contain;
  // max-width: 510px;
  // max-height: 500px;
  position: absolute;
}
`
export const ImgClock = styled.img`
  position: absolute;
  bottom: 0;
  left: 174px;
  z-index: 1000;
  @media screen and (max-width: ${breakpoints.sm}) {
    bottom: -20px;
    left: -6px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    bottom: -20px;
    left: 10px;
  }
}
`

export const LottieWrapper = styled.div`
  z-index: 600;
  width: 100%;
  background-color: transparent;
  position: relative;
`

export const ButtonCustom = styled.button`
  padding: 16px 36px;
  gap: 10px;
  border-radius: 50px;
  font-family: Outfit;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
  background: #FD4560;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99999999999;
  &:hover {
    background: #fd4560c2;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 16px;
  }
`

export const Desc = styled.div`
  font-family: Outfit;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
  max-width: 38%;
  @media screen and (max-width: ${breakpoints.md}) {
    max-width: 100%;
    font-size: 24px;
  }
`
export const PeopleNumber = styled.span`
  color: #D73842;
`