export const breakpoints = {
    xs: '400px',
    sm: '450px',
    md: '767px',
    lg: '1024px',
    ipadAir: '1200px',
    ipadPro: '1366px',
    xl: '1440px',
  
    height_xs: '653px',
    ipadMini: '768px',
    height_lg: '820px'
  };
  